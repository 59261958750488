import { Autocomplete } from '@react-google-maps/api';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { MdClear } from 'react-icons/md';
import { GOOGLE_MAPS_REQUESTED_FIELDS } from '../constants/GoogleMapsConstants';
import { useGoogleMapsAPILoader } from '../hooks/useGoogleMapsAPILoader';
import { EnumMap } from '../types';
import ServerLogger from '../utils/ServerLogger';
import { getRestrictedCountry } from '../utils/SiteConfiguration';

type AutocompleteType = 'primary' | 'secondary' | 'gemini';

interface GoogleAutocompleteSearchBarProps {
  icon?: React.ReactElement;
  clearIcon?: React.ReactElement;
  initialPlace?: string;
  placeholder?: string;
  onPlaceChange(place: google.maps.places.PlaceResult): void;
  showBorder?: boolean;
  variant?: AutocompleteType;
}

const GoogleAutocompleteSearchBar: React.FC<
  GoogleAutocompleteSearchBarProps
> = ({
  initialPlace,
  placeholder = 'Search by address or postal code',
  icon = <AiOutlineSearch className='text-black' size={20} />,
  clearIcon = <MdClear className='text-black' size={20} />,
  onPlaceChange,
  variant = 'primary',
}) => {
  const router = useRouter();
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete>(undefined);
  const [focus, setFocus] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const restrictedCountry = getRestrictedCountry();

  const inputVariantClassEnumMap: EnumMap<AutocompleteType, string> = {
    primary: 'font-primary-light p-2.5 bg-white text-black',
    secondary: 'font-primary-light p-1.5 bg-white text-black',
    gemini: 'font-inter font-normal text-cobalt bg-off-white px-3 py-1.5',
  };

  const containerVariantClassEnumMap: EnumMap<AutocompleteType, string> = {
    primary: classNames('bg-white px-3 py-1 rounded border border-gray-200', {
      'ring-2 ring-cyan-200': focus,
    }),
    secondary: 'bg-white px-3 py-1 rounded',
    gemini: 'border-[1.5px] border-cobalt bg-off-white pr-2',
  };

  const iconVariantEnumMap: EnumMap<
    AutocompleteType,
    React.ReactElement | null
  > = {
    primary: icon,
    secondary: icon,
    gemini: null,
  };

  const isLoaded = useGoogleMapsAPILoader();

  const onLoad = useCallback(
    (autocomplete: React.SetStateAction<google.maps.places.Autocomplete>) => {
      setAutocomplete(autocomplete);
    },
    [setAutocomplete]
  );

  const onPlaceChanged = useCallback(() => {
    if (autocomplete) {
      const place = autocomplete.getPlace();

      ServerLogger.debug('Place selected:', place);
      // only trigger this if formatted_address is available.
      if (place?.formatted_address) {
        onPlaceChange(place);
      }
    } else {
      ServerLogger.error(
        'Autocomplete is null, cannot send place onPlaceChanged.'
      );
    }
  }, [autocomplete, onPlaceChange]);

  useEffect(() => {
    setSearchTerm(initialPlace);
  }, [initialPlace]);

  const handleClearInput = (): void => {
    setSearchTerm('');
    onPlaceChange(undefined);
    router.push({
      pathname: 'listings',
      query: {},
    });
  };

  return (
    <div
      className={classNames(
        'w-full flex flex-row items-center',
        containerVariantClassEnumMap[variant]
      )}
    >
      {iconVariantEnumMap[variant]}
      {isLoaded && (
        <>
          <Autocomplete
            className='w-full'
            onLoad={onLoad}
            onPlaceChanged={onPlaceChanged}
            restrictions={{
              country: restrictedCountry,
            }}
            options={{
              fields: GOOGLE_MAPS_REQUESTED_FIELDS,
            }}
          >
            <input
              defaultValue={initialPlace}
              type='text'
              value={searchTerm}
              placeholder={placeholder}
              className={classNames(
                'w-full text-lg appearance-none border-none focus:outline-none focus:ring-0 primary-light grow',
                inputVariantClassEnumMap[variant]
              )}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Autocomplete>
          {!!searchTerm && (
            <div
              className='cursor-pointer'
              onClick={() => {
                handleClearInput();
              }}
            >
              {clearIcon}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default GoogleAutocompleteSearchBar;
