import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import { RealApiAgentWebsiteResponse } from '../../types/real-api-types';
import {
  checkAvatarExists,
  getCoverImageFromSet,
} from '../../utils/agentWebsiteUtils';
import { getThemeBgColorClassName } from '../../utils/DisplayHelpers';
import { getInitialFilter } from '../../utils/PropertiesUtils';
import { isCASiteOnly } from '../../utils/SiteConfiguration';
import GoogleAutocompleteSearchBar from '../GoogleAutocompleteSearchBar';
import { ThemeContext } from '../ThemeProvider';
import AgentAvatar from './AgentAvatar';

interface AgentWebsiteHeroHeaderProps {
  agentWebsiteResponse: RealApiAgentWebsiteResponse;
}

const AgentWebsiteHeroHeader: React.FC<AgentWebsiteHeroHeaderProps> = ({
  agentWebsiteResponse,
}) => {
  const router = useRouter();
  const isCASite = isCASiteOnly();

  const colorTheme = useContext(ThemeContext);

  const photoSet = getCoverImageFromSet(
    agentWebsiteResponse.website.photo_sets
  );

  const onPlaceChange = (place: google.maps.places.PlaceResult): void => {
    router.push({
      pathname: '/listings',
      query: {
        ...getInitialFilter(place, {
          has_photos: true,
        }),
        agentSlug: agentWebsiteResponse.agent.slug,
      },
    });
  };

  return (
    <div className='h-screen md:h-auto md:min-h-full-3 bg-white w-full relative'>
      <picture className='absolute w-full h-full z-0 left-0 top-0'>
        <source media='(min-width: 778px)' srcSet={photoSet.large} />
        <source media='(min-width: 440px)' srcSet={photoSet.medium} />
        <img
          src={photoSet.small}
          alt='property'
          className='w-full h-full object-cover'
        />
      </picture>
      <div className='p-6 relative w-full h-screen md:h-auto md:min-h-full-3 flex flex-col items-center justify-center'>
        <div className='w-full md:max-w-2xl flex flex-col items-center justify-center'>
          <AgentAvatar
            imageUrl={checkAvatarExists(
              agentWebsiteResponse.agent.cloudinary_image_url
            )}
            name={agentWebsiteResponse.agent?.displayName}
            size='xl'
          />
          <p className='text-4xl lg:text-6xl font-primary-medium text-white mt-3 text-center'>
            {agentWebsiteResponse.website.main_title}
          </p>
          <p className='text-xl font-primary-thin text-white my-6 text-center'>
            {agentWebsiteResponse.website.sub_title}
          </p>
          <GoogleAutocompleteSearchBar
            placeholder={
              isCASite
                ? 'Search by address or postal code'
                : 'Search by address or zip'
            }
            onPlaceChange={onPlaceChange}
          />
        </div>
      </div>
      <div
        className={classNames(
          'absolute bottom-0 left-0 w-full h-1.5  border-t border-blue-800',
          getThemeBgColorClassName(colorTheme)
        )}
      />
    </div>
  );
};

export default AgentWebsiteHeroHeader;
