import classNames from 'classnames';
import React, { useContext } from 'react';
import { getThemeTextColorClassName } from '../../utils/DisplayHelpers';
import { ThemeContext } from '../ThemeProvider';

export interface AgentFeatureCardProps {
  IconComponent: React.ReactElement;
  title: string;
  subtitle: string;
}

const AgentFeatureCard: React.FC<AgentFeatureCardProps> = ({
  IconComponent,
  title,
  subtitle,
}) => {
  const colorTheme = useContext(ThemeContext);

  return (
    <div className='flex flex-col space-y-3'>
      <div className={classNames(getThemeTextColorClassName(colorTheme))}>
        {IconComponent}
      </div>
      <p className='text-lg md:text-2xl font-primary-medium text-dark'>
        {title}
      </p>
      <p className='text-lg md:text-2xl font-primary-light text-dark'>
        {subtitle}
      </p>
    </div>
  );
};

export default AgentFeatureCard;
