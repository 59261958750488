import React from 'react';
import { Testimony } from '../../types/real-api-types';
import TextHeading from '../TextHeading';

interface AgentTestimonialsTextOnlyProps {
  items: Testimony[];
  href: string;
  limit?: number;
}

const AgentTestimonialsTextOnly: React.FC<AgentTestimonialsTextOnlyProps> = ({
  items,
  href,
  limit = 3,
}) => {
  const hasMore = items.length > limit;

  const action = !hasMore ? undefined : { href, text: 'See More >' };

  return (
    <div>
      <TextHeading title='Testimonials' action={action} />
      <div className='grid grid-cols-1 md:grid-cols-3 gap-5 mt-8'>
        {items.slice(0, limit).map((item) => (
          <div
            key={item.customer_name}
            className='border p-4 space-y-1 break-all'
          >
            <p className='text-xl md:text-2xl text-dark font-primary-medium'>
              {item.customer_name}
            </p>
            <p className='text-xl md:text-2xl text-dark font-primary-light'>
              {item.content}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AgentTestimonialsTextOnly;
