import React from 'react';
import AgentFeatureCard, { AgentFeatureCardProps } from './AgentFeatureCard';

interface AgentFeaturesProps {
  features: AgentFeatureCardProps[];
}

const AgentFeatures: React.FC<AgentFeaturesProps> = ({ features }) => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-20'>
      {features.map((feature) => (
        <AgentFeatureCard key={feature.title} {...feature} />
      ))}
    </div>
  );
};

export default AgentFeatures;
