// eslint-disable-next-line import/no-nodejs-modules
import { UrlObject } from 'url';
import classNames from 'classnames';
import Link from 'next/link';
import React, { useContext } from 'react';
import { getThemeTextColorClassName } from '../utils/DisplayHelpers';
import { ThemeContext } from './ThemeProvider';

interface TextHeadingProps {
  title: string;
  action?: {
    text: string;
    href: string | UrlObject;
    as?: string;
  };
}

const TextHeading: React.FC<TextHeadingProps> = ({ title, action }) => {
  const colorTheme = useContext(ThemeContext);

  return (
    <div className='flex flex-col md:flex-row md:items-center md:justify-between md:space-x-4 space-y-1 md:space-y-0'>
      <p className='text-2xl md:text-4xl font-primary-medium text-dark'>
        {title}
      </p>
      {!!action && (
        <Link href={action.href} as={action.as}>
          <a
            className={classNames(
              'text-lg font-primary-medium max-w-xs whitespace-nowrap hover:underline',
              getThemeTextColorClassName(colorTheme)
            )}
          >
            {action.text}
          </a>
        </Link>
      )}
    </div>
  );
};

export default TextHeading;
