// eslint-disable-next-line import/no-nodejs-modules
import { ParsedUrlQuery } from 'querystring';
import { GetServerSideProps, NextPage } from 'next';
import React, { useCallback, useEffect, useState } from 'react';
import AgentFeatures from '../../components/agent/AgentFeatures';
import AgentLayoutRedesigned from '../../components/agent/AgentLayoutRedesigned';
import AgentWebsiteHeroHeader from '../../components/agent/AgentWebsiteHeroHeader';
import Seo from '../../components/Seo';
import AgentTestimonialsTextOnly from '../../components/testimonials/AgentTestimonialsTextOnly';
import ThemeProvider from '../../components/ThemeProvider';
import { useGoogleMapsAPILoader } from '../../hooks/useGoogleMapsAPILoader';
import { OfficesResponse } from '../../openapi/bff';
import ContentfulService from '../../services/ContentfulService';
import { CommonPageDataProps } from '../../types';
import { RealApiAgentWebsiteResponse } from '../../types/real-api-types';
import { isRealAgent } from '../../utils/AgentUtils';
import { getAgentWebsiteApiResponse } from '../../utils/agentWebsiteUtils';
import {
  Feature1SVG,
  Feature2SVG,
  Feature3SVG,
} from '../../utils/inlineSVGUtils';
import {
  getFilteredProperties,
  getPropertiesFilterFromAgentWebsiteResponse,
  isOldAgentWebsiteRespose,
} from '../../utils/PropertiesUtils';
import BFFApiService from '../../services/BFFApiService';
import ErrorService from '../../services/ErrorService';
import ServerLogger from '../../utils/ServerLogger';

interface AgentWebsiteIndexPageProps extends CommonPageDataProps {
  agentWebsiteResponse: RealApiAgentWebsiteResponse;
  allOffices: OfficesResponse;
}

interface PageParams extends ParsedUrlQuery {
  agentSlug: string;
}

const AgentWebsiteIndexPage: NextPage<AgentWebsiteIndexPageProps> = ({
  agentWebsiteResponse,
  countriesWithStates,
  footerContent,
  allOffices,
}) => {
  const [disclaimers, setDisclaimers] = useState([]);
  const isLoaded = useGoogleMapsAPILoader();

  const fetchProperties = useCallback(async () => {
    if (!isOldAgentWebsiteRespose(agentWebsiteResponse)) {
      const filter = await getPropertiesFilterFromAgentWebsiteResponse(
        agentWebsiteResponse
      );
      const res = await getFilteredProperties(
        filter,
        {
          slug:
            (agentWebsiteResponse.agent.id as unknown as string) ||
            agentWebsiteResponse.agent.slug,
        },
        4
      );
      setDisclaimers(res ? res.legalese : []);
    }
  }, [agentWebsiteResponse]);

  useEffect(() => {
    if (isLoaded) {
      fetchProperties();
    }
  }, [fetchProperties, isLoaded]);

  return (
    <ThemeProvider theme={agentWebsiteResponse.website.color_theme}>
      <AgentLayoutRedesigned
        agentWebsiteResponse={agentWebsiteResponse}
        countriesWithStates={countriesWithStates}
        showFooterCTA
        variant='transparent'
        legalese={disclaimers}
        footerContent={footerContent}
        allOffices={allOffices}
      >
        <Seo
          title={agentWebsiteResponse.agent.name}
          description={agentWebsiteResponse.website.main_title}
          imageUrl={agentWebsiteResponse.agent.profile_image.main}
        />
        <AgentWebsiteHeroHeader agentWebsiteResponse={agentWebsiteResponse} />
        <div className='w-full max-w-screen-lg mx-auto bg-white px-6 md:px-0 py-10 md:py-20'>
          <p className='md:text-center text-2xl md:text-5xl font-primary-medium text-dark mb-4 md:mb-6'>
            {agentWebsiteResponse.website.bottom_title}
          </p>
          <p className='md:text-center text-lg md:text-2xl font-primary-light px-0 md:px-40'>
            {agentWebsiteResponse.website.bottom_sub_title}
          </p>
        </div>
        <div className='bg-gray-50'>
          <div className='w-full max-w-7xl mx-auto px-6 lg:px-0 py-10 md:py-20'>
            <AgentFeatures
              features={[
                {
                  IconComponent: <Feature1SVG />,
                  title: agentWebsiteResponse.website.title_feature_1,
                  subtitle: agentWebsiteResponse.website.subtitle_feature_1,
                },
                {
                  IconComponent: <Feature2SVG />,
                  title: agentWebsiteResponse.website.title_feature_2,
                  subtitle: agentWebsiteResponse.website.subtitle_feature_2,
                },
                {
                  IconComponent: <Feature3SVG />,
                  title: agentWebsiteResponse.website.title_feature_3,
                  subtitle: agentWebsiteResponse.website.subtitle_feature_3,
                },
              ]}
            />
          </div>
        </div>
        {!!agentWebsiteResponse.website.testimonies?.length && (
          <div className='w-full max-w-7xl mx-auto px-6 lg:px-0 py-10 md:py-20'>
            <AgentTestimonialsTextOnly
              items={agentWebsiteResponse.website.testimonies}
              href={`${agentWebsiteResponse.agent.slug}/about`}
            />
          </div>
        )}
      </AgentLayoutRedesigned>
    </ThemeProvider>
  );
};

// export const getStaticPaths: GetStaticPaths = async () => {
//   return { paths: [], fallback: 'blocking' };
// };

export const getServerSideProps: GetServerSideProps<
  AgentWebsiteIndexPageProps,
  PageParams
> = async (ctx) => {
  ServerLogger.log(
    `Fetching /${ctx.params.agentSlug}`,
    '- [User-Agent]:',
    ctx.req.headers['user-agent']
  );
  const contentfulService = new ContentfulService();
  const bffApiService = new BFFApiService();

  const [agentWebsiteResponse, countriesWithStates, footerContent, allOffices] =
    await Promise.all([
      getAgentWebsiteApiResponse(ctx.params.agentSlug),
      contentfulService.getCountriesWithStates(),
      contentfulService.getFooterContent(),
      bffApiService.fetchAllOfficialOffices(),
    ]);

  if (!agentWebsiteResponse) {
    return { notFound: true };
  }

  if (!countriesWithStates) {
    const error = new Error('Unable to load agent website page');
    ErrorService.notify('Unable to load agent website page!', error, {
      apiCalls: {
        countriesWithStates: !!countriesWithStates,
      },
    });

    throw error;
  }

  if (isRealAgent(agentWebsiteResponse.agent)) {
    return {
      redirect: {
        destination: `/${ctx.params.agentSlug}/properties`,
        permanent: true,
      },
    };
  }

  return {
    props: {
      agentWebsiteResponse,
      countriesWithStates,
      footerContent,
      allOffices,
    },
  };
};

export default AgentWebsiteIndexPage;
