import React from 'react';

export const Feature1SVG = (): React.ReactElement => (
  <svg
    aria-labelledby='buysellrent'
    role='img'
    viewBox='0 0 66 66'
    xmlns='http://www.w3.org/2000/svg'
    style={{ width: 60, height: 60 }}
  >
    <g data-name='Layer 2' id='Layer_2'>
      <g data-name='Layer 1' id='Layer_1-2'>
        <circle
          className='cls-1'
          cx='33'
          cy='33'
          r='32.5'
          style={{
            fill: 'white',
            strokeMiterlimit: 10,
            stroke: 'currentColor',
          }}
        />
        <polygon
          className='cls-1'
          points='33 14.5 18.43 29.02 18.43 49.5 47.56 49.5 47.56 29.02 33 14.5'
          style={{
            stroke: 'currentColor',
            fill: 'white',
            strokeMiterlimit: 10,
          }}
        />
        <path
          className='cls-2'
          d='M37.5,34.8A5.83,5.83,0,1,1,31.67,29,5.8,5.8,0,0,1,37.5,34.8Z'
          style={{ stroke: 'currentColor', fill: '#fff' }}
        />
        <path
          className='cls-2'
          d='M40.78,44l-5.05-5Z'
          style={{ stroke: 'currentColor' }}
        />
      </g>
    </g>
  </svg>
);

export const Feature2SVG = (): React.ReactElement => (
  <svg
    aria-labelledby='proventtrackrecord'
    role='img'
    viewBox='0 0 66 66'
    xmlns='http://www.w3.org/2000/svg'
    style={{ width: 60, height: 60 }}
  >
    <g data-name='Layer 2' id='Layer_2'>
      <g data-name='Layer 1' id='Layer_1-2'>
        <circle
          className='cls-1'
          cx='33'
          cy='33'
          r='32.5'
          style={{
            fill: 'white',
            strokeMiterlimit: 10,
            stroke: 'currentColor',
          }}
        />
        <path
          className='cls-1'
          d='M26.14,32.86s7.21-4.28,7.4-13c0-2.29-.14-3.83,1.09-3.83s6.12.4,2.13,10.75c0,0-.73,2,1.07,1.63s12.06-2.36,9.62,3.83c0,0,2.36,2.78,0,4.81a4.5,4.5,0,0,1-1.14,5s1.57,5.06-8,5-12.2-1-12.2-1Z'
          style={{
            fill: 'white',
            strokeMiterlimit: 10,
            stroke: 'currentColor',
          }}
        />
        <rect
          className='cls-2'
          height='17.44'
          width='8.64'
          x='17.5'
          y='30.53'
          style={{
            stroke: 'currentColor',
            fill: 'none',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
      </g>
    </g>
  </svg>
);

export const Feature3SVG = (): React.ReactElement => (
  <svg
    aria-labelledby='cuttingedge'
    role='img'
    viewBox='0 0 66 66'
    xmlns='http://www.w3.org/2000/svg'
    style={{ width: 60, height: 60 }}
  >
    <g data-name='Layer 2' id='Layer_2'>
      <g data-name='Layer 1' id='Layer_1-2'>
        <circle
          className='cls-1'
          cx='33'
          cy='33'
          r='32.5'
          style={{
            fill: 'white',
            strokeMiterlimit: 10,
            stroke: 'currentColor',
          }}
        />
        <rect
          className='cls-2'
          height='42'
          rx='4.49'
          ry='4.49'
          width='22'
          x='22'
          y='12'
          style={{
            stroke: 'currentColor',
            fill: 'none',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
        <line
          className='cls-2'
          x1='29.73'
          x2='36.27'
          y1='15.63'
          y2='15.63'
          style={{
            fill: 'none',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            stroke: 'currentColor',
          }}
        />
        <circle
          className='cls-2'
          cx='33'
          cy='49.2'
          r='1.32'
          style={{
            fill: 'none',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            stroke: 'currentColor',
          }}
        />
      </g>
    </g>
  </svg>
);
