import React, { useContext } from 'react';
import Image from 'next/image';
import classNames from 'classnames';
import { ThemeContext } from '../ThemeProvider';
import { getThemeBorderClassName } from '../../utils/DisplayHelpers';
import { parseInitials, stringToNumber } from '../../utils/StringUtils';
import { EnumMap } from '../../types';

export type AvatarSizeType = 'xxs' | 'xs' | 'sm' | 'lg' | 'xl';

interface AgentAvatarProps {
  imageUrl?: string;
  name: string;
  size: AvatarSizeType;
}

const AgentAvatar: React.FC<AgentAvatarProps> = ({ imageUrl, name, size }) => {
  const colorTheme = useContext(ThemeContext);

  const colors = [
    'bg-primary',
    'bg-success',
    'bg-error',
    'bg-warning',
    'bg-dark',
  ];

  const avatarSizeToClassNameMap: EnumMap<AvatarSizeType, string> = {
    xxs: 'h-6 w-6',
    xs: 'h-8 w-8',
    sm: 'h-10 w-10',
    lg: 'h-20 w-20',
    xl: 'h-28 w-28',
  };

  const textSizeToClassNameMap: EnumMap<AvatarSizeType, string> = {
    xxs: 'text-xs',
    xs: 'text-sm',
    sm: 'text-lg',
    lg: 'text-3xl',
    xl: 'text-4xl',
  };

  if (imageUrl) {
    return (
      <div
        className={classNames(
          'relative border-2 rounded-full w-32 h-32 overflow-hidden',
          getThemeBorderClassName(colorTheme)
        )}
      >
        <Image
          src={imageUrl}
          alt='agent-avatar'
          objectFit='cover'
          layout='fill'
          className='w-full h-full'
        />
      </div>
    );
  }

  return (
    <span
      className={classNames(
        'inline-flex items-center justify-center rounded-full border-2',
        avatarSizeToClassNameMap[size],
        colors[stringToNumber(name) % colors.length],
        getThemeBorderClassName(colorTheme)
      )}
    >
      <span
        className={classNames(
          'font-primary-medium leading-1 text-white',
          textSizeToClassNameMap[size]
        )}
      >
        {parseInitials(name)}
      </span>
    </span>
  );
};

export default AgentAvatar;
