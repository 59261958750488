import Head from 'next/head';
import React from 'react';

export interface SeoProps {
  title: string;
  description: string;
  imageUrl?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
}

const Seo: React.FC<SeoProps> = ({
  title,
  description,
  imageUrl,
  ogImage,
  ogTitle,
  ogDescription,
}) => {
  return (
    <Head>
      {/* Primary Meta Tags */}
      <title>{title}</title>
      <meta name='title' content={title} />
      <meta name='description' content={description} />

      {/* Open Graph / Facebook */}
      <meta property='og:type' content='website' />
      <meta property='og:title' content={ogTitle || title} />
      <meta property='og:description' content={ogDescription || description} />
      {!!imageUrl && <meta property='og:image' content={ogImage || imageUrl} />}

      {/* Twitter  */}
      <meta property='twitter:card' content='summary_large_image' />
      <meta property='twitter:title' content={title} />
      <meta property='twitter:description' content={description} />
      {!!imageUrl && <meta property='twitter:image' content={imageUrl} />}
    </Head>
  );
};

export default Seo;
